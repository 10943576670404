import { Suspense, useCallback, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useQuery } from "react-query";
import { addHeaderToken } from "api/config";
import { property } from "api/user";
import { useAlert } from "utils/helper";
import { isLoginState, userPropertyState } from "store/user";
import env from "config/env";
import { default as routes } from "./pages";
import GlobalNavigationBar from "components/GlobalNavigationBar";

function App() {
  const setUserProperty = useSetRecoilState(userPropertyState);
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);
  const alert = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const element = useRoutes(routes);

  // get user property
  const { refetch } = useQuery(["property"], property, {
    onSuccess({ data }) {
      if (!data.data) return;
      const { user, access_token, refresh_token } = data.data;

      localStorage.setItem(env.SESSION_KEY, refresh_token);
      addHeaderToken(access_token); // new token set in axios header
      setIsLogin(true);
      setUserProperty(user);
    },
    onError(err: any) {
      logoutHandler();
      alert.error(err.message);
    },
    enabled: false,
  });

  const logoutHandler = useCallback(() => {
    localStorage.removeItem(env.SESSION_KEY);
    setUserProperty(null);
    navigate("/login");
    setIsLogin(false);
  }, [navigate, setIsLogin, setUserProperty]);

  useEffect(() => {
    const { pathname } = location;
    const token = localStorage.getItem(env.SESSION_KEY); // refresh token

    // 버전 체크
    // navigator.serviceWorker.getRegistrations().then((registration) => {
    //   registration.forEach((reg) => {
    //     reg.waiting?.postMessage({ type: "SKIP_WAITING", version: "v3" });
    //   });
    // });

    if (pathname !== "/login" && !token) {
      navigate("/login");
      return;
    } else if (pathname !== "/login" && token && !isLogin) {
      addHeaderToken(token);
      refetch();
      return;
    }
  }, [location, navigate, isLogin, refetch]);

  return (
    <div className="App">
      <GlobalNavigationBar onLogout={logoutHandler} />
      <Suspense fallback={<div className="m-auto text-center">loading...</div>}>
        <div className="w-full h-[calc(100%-68px)] overflow-y-auto">{element}</div>
      </Suspense>
    </div>
  );
}

export default App;
