// import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { classNameJoin } from "utils/ui.lib";
import Icon from "./Icon";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  suffix?: any;
  onEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSuffixClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Input = ({ className, inputClassName, suffix, onKeyUp, onEnter, onSuffixClick, ...props }: Props) => {
  const keyupHandler = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyUp && onKeyUp(ev);

    if (ev.key.toUpperCase() === "ENTER") {
      onEnter && onEnter(ev);
    }
  };

  return (
    <label className={classNameJoin(["flex w-full h-9 relative", className])}>
      <input
        {...props}
        className={classNameJoin([
          "flex-1 w-full h-9 bg-neutral-100 px-3 text-sm border-none outline-none rounded-none disabled:select-none disabled:text-neutral-600",
          inputClassName,
        ])}
        onKeyUp={keyupHandler}
      />

      {suffix && (
        <button
          tabIndex={-1}
          disabled={props.disabled}
          className="h-9 w-9 cursor-pointer outline-none disabled:text-neutral-600 disabled:cursor-default"
          onClick={onSuffixClick}
        >
          <Icon icon={suffix} fontSize={14} />
        </button>
      )}
    </label>
  );
};

export default Input;
