// import {
//   FontAwesomeIcon,
//   FontAwesomeIconProps,
// } from "@fortawesome/react-fontawesome";

interface Props {}

const Icon = ({ ...props }: any) => {
  //  <FontAwesomeIcon {...props} />;
  return <></>;
};

export default Icon;
