import Input from "./Input";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  suffix?: any;
  onEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const NumbericInput = ({ type = "text", onChange, ...props }: Props) => {
  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;

    if (value === "") {
      onChange && onChange(ev);
      return;
    }

    // 숫자가 아닌 문자 삭제
    if (!/^[0-9][0-9|,|.]*$/.test(value)) {
      ev.target.value = value.slice(0, value.length - 1);
      return;
    }

    onChange && onChange(ev);
  };

  return <Input {...props} onChange={changeHandler} />;
};

export default NumbericInput;
