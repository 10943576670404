import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import env from "config/env";
import App from "./App";
import AlertProvider from "contexts/AlertProvider";
import ConfirmProvider from "contexts/ConfirmProvider";
import ModalProvider from "contexts/ModalProvider";
import modals from "contexts/modals";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "styles/global.css";
import "styles/ui/vTable.css";
import "./config/icon";
import CompanyPopupProvider from "contexts/CompanyPopupProvider";
import Compose from "contexts/Compose";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as HTMLElement);

console.log(`service version ${env.APP_VERSION}`);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ModalProvider components={modals}>
          <Compose components={[AlertProvider, ConfirmProvider, CompanyPopupProvider]}>
            <App />
          </Compose>
        </ModalProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </RecoilRoot>
);

// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

reportWebVitals();
